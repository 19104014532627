import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='關於這裡'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            {/* <Button sx={styles.button}>Contact Me</Button> */}
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='介紹'>
                <Text variant='p'>
                  Hi, 我是尼森<br/>
                </Text>
                <Text variant='p'>
                 
                </Text>
                <Text variant='p'>
                  這個部落格嘗試關注：
                  <ul>   
                    <li>
                      <b>技術發展史</b>：工程、技術是人類「表達」自己的其中一種方式。人類就是透過工程技術(engineering)，才得以把腦中許多理想(idea)帶來真實世界。思想決定了我們可以做到什麼，但相反的，技術發展的程度也限制了人類表達的自由和創新的進程。因此我對技術史有興趣，想了解技術的發展是如何限制、幫助我們更加自由的表達。
                    </li>
                    <br/>
                    <li>
                      <b>技術哲學</b>：比起技術細節，我意識到自己對於技術背後代表的世界觀、設計選擇、抽象思維...更感興趣。所以這裡除了會記錄一些學習到的技術，更會關注一些技術、產品背後的哲學或意圖。
                    </li>
                    <br/>
                    <li>
                      <b>科技產業研究</b>：技術的發展跟「社會環境」息息相關，因此除了理解技術本身，也希望認識一個時代的人類與科技的互動方式，包含發展趨勢、商業模式，並認識在一個科技的背後，人們共同相信和追求的價值。
                    </li>
                  </ul>  
                </Text>
              </Section>
              <br/>
              {/* <Section title='背景'>
                <ol>
                  <li>
                    <b>關注創新導向的技術解決方案</b>
                    <br/>
                    <br/>
                    《創意競擇》這本書提到，Apple 在開發新產品時，並非直接找來 PM、設計師、前端、後端..組成團隊，然後開始實作。而是讓工程師自己來 design、prototyping、demo....，重複循環直到找到可行的創新方案。同樣的，我希望自己並非是專注於單純所謂前後端技術的工程師，而是能於陌生問題中，藉由各種技術與方法，協助找到創新解決方案的角色。
                    <br/>
                    
                  </li>
                  <br/>
                  <li>
                    <b>關注商業、科技、人文跨領域學科</b>
                    <br/>
                    <br/>
                    曾經是臺大商學研究社成員，經過的一年培養商業分析與問題解決的能力，並試圖整合科技與商業技能，主要研究 5G 和遊戲串流平台對傳統遊戲廠在商業上的影響。
                    曾為 2017, 2018 臺大哲學桂冠獎得主，利用香濃訊息論（information theory)、政治權力模型（Selectorate theory）等模型理論，利用科學方式來分析抽象的社會與人文議題。
                  </li>
                </ol>  
              </Section>
              <br/> */}
              {/* <Section title='相關經歷'>
              <Text variant='p'>
                  <ul>
                    <li>均一教育平台 Junyi Academy，軟體工程師</li>
                    <li>為臺灣而教 Teach for Taiwan ，兼職軟體工程師 / 軟體志工 team lead</li>
                    <li>AlphaCamp / Wehelp 計畫，助教</li>
                  </ul>                
                </Text>
                <Text variant='p'>
                  其他相關經驗：
                    <ul>
                      <li>美國矽谷新創，前端工程實習生</li>
                      <li>醫院醫學研發部，兼職後端工程師</li>
                    </ul>    
                </Text>
                <Text variant='p'>
                  同時，對軟體創業 / 商業 / 工程師職涯 / 寫作 有興趣，曾為
                    <ul>
                      <li>2020 Meet.jobs，專欄記者</li>
                      <li>2020 臺大職場手冊，總編輯</li>
                      <li>臺大商學研究社 BizPro，成員<br/></li>
                      <li>2017, 2018 臺大哲學桂冠獎，首獎、貳獎得主<br/></li>
                    </ul>    
                </Text>
              </Section> */}

            </Box>

            {/* <Box sx={styles.column}>
              <Section title='前端技術'>
                <Card variant='paper'>
                    During the brainstorming process, art directors, co-workers,
                    and clients are engaged in imagining what the finished piece
                    or scene might look like.
                </Card>
              </Section>
              <Divider />
              <Section title='Digital Marketing'>
                <Card variant='paper'>
                  Digital marketing channels are systems based on the Internet
                  that can create, accelerate, and transmit product value from
                  producer to a consumer terminal, through digital networks
                </Card>
              </Section>
              <Divider />
              <Section title='Creative Designing'>
                <Card variant='paper'>
                  A core responsibility of the designer's job is to present
                  information in a way that is both accessible and memorable.
                </Card>
              </Section>
            </Box> */}
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
